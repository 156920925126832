import decodeURIforEUCKR from '@/helpers/EucKrDecoder';
import { PageProps } from 'gatsby';
import queryString from 'query-string';
import React, { useEffect } from 'react';

const InicisAccountCheckPage: React.FC<PageProps> = ({ location }) => {
  useEffect(() => {
    // 파라미터 디코딩중 에러나면(ie에서 발생하기도 함) 디코딩 없이 출력
    let params;
    let isDecoded = false;
    try {
      params = queryString.parse(decodeURIforEUCKR(location.search));
      isDecoded = true;
    } catch (e) {
      params = queryString.parse(location.search);
    }
    const opener = window.opener || window.parent;
    if (opener) {
      opener.postMessage(
        {
          type: `inicis-account-check`,
          resultCode: params.strRet,
          resultMessage: params.strErrMsg,
          isDecoded,
        },
        `*`,
      );
      window.close();
    }
  }, [location.search]);

  return <p>계좌 확인중입니다. 잠시만 기다려주세요.</p>;
};

export default InicisAccountCheckPage;
